import React, {useEffect} from 'react';
import {Modal, Space, Table, Tooltip} from "antd";
import { useIntl } from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {
    CheckCircleTwoTone,
    ExclamationCircleOutlined
} from "@ant-design/icons";

import {SearchTable} from "../../util/SearchTable";
import {
    activeManagerStatusAccount,
    bindAccount,
    deleteAccount,
    getAuthUserReducer, getManageAccount,
    updateAccount
} from "../../appRedux/actions";
import ModalAddBalance from "./ModalAddBalance";
import ModalEditAccount from "./ModalEditAccount";
import ModalTransferBalance from "./ModalTransferBalance";
import {addBalance} from "../../appRedux/actions/Balance";
import CustomSorter from "../../util/CustomSorter";
import IntlMessages from "../../util/IntlMessages";

const {confirm} = Modal;

const TableManageAccount = ({pagination, setPagination}) => {

    const dispatch = useDispatch()
    const intl = useIntl();
    const {authUser} = useSelector(({auth}) => auth);
    const {id, balance, is_superuser} = authUser
    const {manageAccounts} = useSelector(({customer}) => customer)
    const {getColumnSearchProps} = SearchTable()

    useEffect(() => {
        if (manageAccounts?.results && is_superuser) {
            const mainAccount = manageAccounts.results.filter(item => item.id === id)
            if (mainAccount[0] && balance !== mainAccount[0].balance) {
                dispatch(getAuthUserReducer())
            }
        }
    }, [manageAccounts])

    useEffect(() => {
        dispatch(getManageAccount(pagination))
    }, [pagination])

    const showConfirmActive = (text) => {
        confirm({
            title: !text.is_active
                ? intl.formatMessage({id: "account.tableManageAccount.questionActivationAccount"})
                : intl.formatMessage({id: "account.tableManageAccount.questionDeactivationAccount"}),
            icon: <ExclamationCircleOutlined/>,
            content: !text.is_active
                ? intl.formatMessage({id: "account.tableManageAccount.answerActivationAccount"})
                : intl.formatMessage({id: "account.tableManageAccount.answerDeactivationAccount"}),

            onOk() {
                dispatch(activeManagerStatusAccount({id: text.id, is_active: !text.is_active}))
            },

            onCancel() {},
        });
    };

    const showConfirmDelete = (text) => {
        confirm({
            title: intl.formatMessage({ id: "account.tableManageAccount.questionDeleteAccount" }),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({ id: "account.tableManageAccount.answerDeleteAccount" }),
            onOk() {
                dispatch(deleteAccount({
                    dataAccount: {id: text.id},
                    querySetParams: pagination
                }))
            },
            onCancel() {},
        });
    };

    const fromModalAddBalance = (val) => {
        dispatch(addBalance(val));
    }

    const showBalanceApp = (text) => {
        if (authUser.is_superuser) {
            return <ModalAddBalance text={text} fromModalAddBalance={fromModalAddBalance}/>
        } else {
            // return <ModalTransferBalance text={text}/>
            return <ModalAddBalance text={text} fromModalAddBalance={fromModalAddBalance}/>
        }
    }

    const fromModalEditAccount = (val) => {
        authUser.is_superuser ? dispatch(updateAccount(val)) : dispatch(activeManagerStatusAccount(val))
    }

    const sortState = (currentSortState, type) => {
        let nextSortState;

        const res = currentSortState ? currentSortState.includes(type) : false

        if (res) {
            switch (currentSortState) {
                case 'active_customer':
                case 'count_customer':
                    nextSortState = `-${currentSortState}`;
                    break
                case '-active_customer':
                case '-count_customer':
                    nextSortState = null;
                    break
                default:
                    nextSortState = type;
            }
        } else {
            nextSortState = type
        }


        setPagination(prevPagination => ({...prevPagination, orderingManageAccounts: nextSortState}))
        nextSortState
            ? localStorage.setItem('orderingManageAccounts', nextSortState)
            : localStorage.removeItem('orderingManageAccounts')
        return nextSortState;
    }

    const column = [
        {
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: 25,
            render: value => (
                <>
                    {value ? <CheckCircleTwoTone twoToneColor="#52c41a"/> : ""}
                </>
            )
        },
        {
            title: <><IntlMessages id="account.tableManageAccount.account"/></>,
            dataIndex: 'email',
            key: 'email', ...getColumnSearchProps('email'),
        },
        {
            title: <><IntlMessages id="account.tableManageAccount.managerStatus"/></>,
            dataIndex: 'is_staff',
            key: 'is_staff',
            hidden: true,
            render: value => (
                <>
                    {value ? 'Да' : ""}
                </>
            )
        },
        {
            title: <><IntlMessages id="account.tableManageAccount.manager"/></>,
            dataIndex: 'manager',
            key: 'manager',
            hidden: true,
            render: value => (
                <>
                    {value ? <CheckCircleTwoTone twoToneColor="#52c41a"/> : ""}
                </>
            )
        },
        {
            title: <><IntlMessages id="account.tableManageAccount.balance"/></>,
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: (
                <CustomSorter sortState={sortState}
                              pagination={pagination}
                              typeSorter={'count_customer'}
                              description={<IntlMessages id="account.tableManageAccount.countCustomer"/>}/>
            ),
            dataIndex: 'count_customer',
            key: 'count_customer',
        },
        {
            title: (
                <CustomSorter sortState={sortState}
                              pagination={pagination}
                              typeSorter={'active_customer'}
                              description={<IntlMessages id="account.tableManageAccount.countCustomerActive"/>}/>
            ),
            dataIndex: 'active_customer',
            key: 'active_customer',
        },
        {
            title: <><IntlMessages id="account.tableManageAccount.action"/></>,
            dataIndex: '',
            align: 'center',
            colSpan: 6,
            key: 'action',
            width: '1%',
            render: text => (
                <>
                    {
                        authUser.id !== text.id &&
                        <Tooltip title={<IntlMessages id="account.tableManageAccount.activationAccount" />}>
                            <i
                                className="icon icon-check"
                                onClick={() => showConfirmActive(text)}
                            />
                        </Tooltip>
                    }
                </>
            )
        },
        {
            colSpan: 0,
            dataIndex: '',
            width: '1%',
            render: text => (
                <>
                    {
                        authUser.is_staff && <ModalEditAccount text={text} fromModalEditAccount={fromModalEditAccount}/>
                    }
                </>
            )
        },
        {
            colSpan: 0,
            dataIndex: '',
            width: '1%',
            hidden: true,
            render: text => (
                <>
                    {
                        authUser.id !== text.id &&
                        <Tooltip title={<IntlMessages id="account.tableManageAccount.statusManager" />}>
                            <i
                                className="icon icon-avatar"
                                onClick={() => dispatch(updateAccount({
                                    id: text.id, is_staff: !text.is_staff
                                }))}
                            />
                        </Tooltip>
                    }
                </>
            )
        },
        {
            colSpan: 0,
            dataIndex: '',
            width: '1%',
            render: text => (showBalanceApp(text))
        },
        {
            colSpan: 0,
            dataIndex: '',
            width: '1%',
            render: text => (
                <>
                    {
                        authUser.is_staff && authUser.id !== text.id &&
                        <Tooltip title={<IntlMessages id="account.tableManageAccount.deleteAccount" />}>
                            <i
                                className="icon icon-trash red"
                                onClick={() => showConfirmDelete(text)}
                            />
                        </Tooltip>
                    }
                </>
            )
        },
        {
            colSpan: 0,
            dataIndex: '',
            width: '1%',
            render: text => (
                <Space>
                    {text.manager !== 0 && <Tooltip title={<IntlMessages id="account.tableManageAccount.unlinkAccount" />}>
                        <i
                            className="icon icon-signin"
                            onClick={() => dispatch(bindAccount({
                                dataAccount: {"email": text.email},
                                querySetParams: pagination
                            }))}
                        />
                    </Tooltip>}
                </Space>
            )
        }
    ]

    const onChange = ({current, pageSize}, filters) => {
        const pureFilters = Object.fromEntries(Object.entries((
            {
                search: filters.email,
            }
        ))
            .filter(([_, v]) => v != null));
        setPagination(prevPagination => ({...prevPagination, page: current, page_size: pageSize, ...pureFilters}))
    }

    return (
        <Table
            rowKey="id"
            className="gx-table-responsive"
            dataSource={manageAccounts?.results}
            size="small"
            columns={!authUser.is_superuser ? column.filter(item => !item.hidden) : column}
            onChange={onChange}
            pagination={{
                showSizeChanger: true,
                defaultPageSize: pagination.page_size,
                total: manageAccounts?.count
            }}
        />
    );
};

export default TableManageAccount;