import React, {useState} from 'react';
import {Form, Input, Modal, Tooltip} from "antd";
import {useDispatch} from "react-redux";
import {editCountry} from "../../appRedux/actions/PacketCountry";
import IntlMessages from "../../util/IntlMessages";

const ModalEditCountry = (props) => {

  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false)

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = values => {
    values.id = props.country
    dispatch(editCountry(values));
    form.resetFields()
  };

  const chowModal = () => {
    setVisible(true)
  }
  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Tooltip
        title={<IntlMessages id="packet.editCountryApp.editCountry"/>}>
        <i
          className="icon icon-edit"
          onClick={chowModal}
        />
      </Tooltip>
      <Modal
        title={<><IntlMessages id="packet.editCountryApp.editCountry"/>` ${props.name}`</>}
        open={visible}
        okText={<IntlMessages id="packet.allApp.change"/>}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          form={form}
          initialValues={{remember: true}}
          name="basic"
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            label={<IntlMessages id="packet.listCountryApp.nameCountry"/>}
            initialValue={`${props.name}`}
            name="name"
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label={<IntlMessages id="packet.listCountryApp.codeCountry"/>}
            initialValue={`${props.code}`}
            name='code'
          >
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
};

export default ModalEditCountry;
