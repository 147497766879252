import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, Row, Tabs, Divider} from "antd";

import AppNotificationContainer from "../../components/AppNotificationContainer";
import ListPackets from "./ListPackets";
import AddPacket from "./AddPacket";
import AddGroup from "./AddGroup";
import ListGroups from "./ListGroups";
import AddChannel from "./AddChannel";
import ListChannels from "./ListChannels";
import {getGroups} from "../../appRedux/actions/PacketGroup";
import AddCountry from "./AddCountry";
import ListCountry from "./ListCountry";
import IntlMessages from "../../util/IntlMessages";

const TabPane = Tabs.TabPane;

const PacketsComponent = () => {

    const {authUser} = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGroups()).then()
    }, []);

    const callback = (key) => {
    };

    return (
        <Card className="gx-card">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Packet" key="1">
                    <Row>
                        {authUser.is_staff &&
                            <Col span={24}>
                                <div className="gx-layout-footer-content gx-mb-2">
                                    <IntlMessages id="packet.indexApp.createPacket"/>
                                </div>
                                <AddPacket/>
                                <Divider style={{'backgroundColor': '#fafafa'}}/>
                            </Col>
                        }
                        <Col span={24}>
                            <div className="gx-layout-footer-content gx-mb-2">
                                <IntlMessages id="packet.indexApp.listPackets"/>
                            </div>
                            <ListPackets/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Group" key="2">
                    <Row>
                        {authUser.is_superuser &&
                            <Col span={24}>
                                <div className="gx-layout-footer-content gx-mb-2">
                                    <IntlMessages id="packet.indexApp.createGroup"/>
                                </div>
                                <AddGroup/>
                                <Divider style={{'backgroundColor': '#fafafa'}}/>
                            </Col>
                        }
                        <Col span={24}>
                            <div className="gx-layout-footer-content gx-mb-2">
                                <IntlMessages id="packet.indexApp.listGroups"/>
                            </div>
                            <ListGroups/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Country" key="3">
                    <Row>
                        {authUser.is_superuser &&
                            <Col span={24}>
                                <div className="gx-layout-footer-content gx-mb-2">
                                    <IntlMessages id="packet.indexApp.createCountry"/>
                                </div>
                                <AddCountry/>
                                <Divider style={{'backgroundColor': '#fafafa'}}/>
                            </Col>
                        }
                        <Col span={24}>
                            <div className="gx-layout-footer-content gx-mb-2">
                                <IntlMessages id="packet.indexApp.listCountries"/>
                            </div>
                            <ListCountry/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Channel" key="4">
                    <Row>
                        {authUser.is_superuser &&
                            <Col span={24}>
                                <div className="gx-layout-footer-content gx-mb-2">
                                    <IntlMessages id="packet.indexApp.createChannel"/>
                                </div>
                                <AddChannel/>
                                <Divider style={{'backgroundColor': '#fafafa'}}/>
                            </Col>
                        }
                        <Col span={24}>
                            <div className="gx-layout-footer-content gx-mb-2">
                                <IntlMessages id="packet.indexApp.listChannels"/>
                            </div>
                            <ListChannels/>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
            <AppNotificationContainer/>
        </Card>
    );
};

export default PacketsComponent;
