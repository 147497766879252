import React, {useState} from "react";
import {Form, Input, InputNumber, Modal, Select, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {editChannel} from "../../appRedux/actions";
import IntlMessages from "../../util/IntlMessages";

const {Option} = Select;

const ModalEditChannel = (props) => {

    const {
        name,
        flussonic,
        channel,
        smart_epg,
        parser_epg,
        reference,
        archive,
        group,
        country,
        parental_control,
        paid
    } = props.text

    const {groups, countries} = useSelector(state => state.packet)
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false)

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = values => {
        values.id = channel
        dispatch(editChannel(JSON.parse(JSON.stringify(values))));
        setOpen(false)
        form.resetFields()
    };
    const chowModal = () => {
        setOpen(true)
        form.setFieldsValue({
            name: name.toString(),
            flussonic: flussonic.toString(),
            smart_epg: smart_epg.toString(),
            parser_epg: parser_epg.toString(),
            reference: reference.toString(),
            archive: archive.toString(),
            paid: paid.toString(),
            parental_control: parental_control.toString(),
            group: group.id,
            country: country.id
        })
    }
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <>
            <Tooltip
                title="Редактирование канала">
                <i
                    className="icon icon-edit"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={`Редактирование канала ${name}`}
                open={open}
                okText={<IntlMessages id="packet.allApp.change"/>}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{remember: true}}
                    name="modalEditChannel"
                    onFinish={onFinish}
                    className="gx-signin-form gx-form-row0"
                >
                    <Form.Item
                        label='Name channel'
                        name="name"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label='Flussonic Id'
                        name="flussonic"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label='Smart Epg'
                        name="smart_epg"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label='Parser Epg'
                        name="parser_epg"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label='Reference'
                        name="reference"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label='Archive'
                        name="archive"
                    >
                        <InputNumber style={{marginRight: 0, width: '100%'}} placeholder={archive} min={0} max={10}
                                     step={1}/>
                    </Form.Item>
                    <Form.Item
                        label='Платный'
                        name="paid"
                    >
                        <Select style={{width: '100%'}}>
                            <Option value="true">Да</Option>
                            <Option value="false">Нет</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='18+'
                        name="parental_control"
                    >
                        <Select style={{width: '100%'}}>
                            <Option value="true">Запретить</Option>
                            <Option value="false">Разрешыть</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Group'
                        name="group"
                    >
                        <Select>
                            {
                                groups.map(x =>
                                    <Option key={x.id} value={x.group}>{x.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Country'
                        name="country"
                    >
                        <Select>
                            {
                                countries.map(x=> <Option key={x.id} value={x.country}>{x.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default ModalEditChannel
