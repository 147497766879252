import React, {useEffect, useState} from 'react';
import {Modal, Tabs, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {getChannelsForPacket, initChannelsForPacket} from "../../appRedux/actions/Packet";
import IntlMessages from "../../util/IntlMessages";

const TabPane = Tabs.TabPane;

const ModalDetailPacket = (values) => {

    const {name, id} = values.text
    const dispatch = useDispatch();
    const {packets} = useSelector(state => state.packet);
    const [visible, setVisible] = useState(false)

    const findChannelsInPackets = packets.find(item => item.id === id)

    useEffect(() => {
        if (findChannelsInPackets.channels.length > 0) {
            setVisible(true)
        }
    }, [findChannelsInPackets])

    const chowModal = () => {
        if (findChannelsInPackets.channels.length !== 0) {
            setVisible(true)
        } else {
            dispatch(getChannelsForPacket(id))
        }
    }

    const handleCancel = () => {
        setVisible(false)
        dispatch(initChannelsForPacket(id))
    }

    return (
        <>
            <Tooltip title={<IntlMessages id="packet.detailPacketApp.detailPacket"/>}>
                <i
                    className="icon icon-all-contacts"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={<><IntlMessages id="packet.detailPacketApp.detailPacket"/>{` -- ${name}`}</>}
                open={visible}
                width={700}
                onCancel={handleCancel}
            >
                <Tabs
                    tabPosition={'left'}
                >
                    {
                        findChannelsInPackets.channels[0] && Object.keys(findChannelsInPackets.channels[0]).map(
                            item =>
                                <TabPane tab={item} key={item}>{
                                    findChannelsInPackets.channels[0][item].map(
                                        (item, i, arr) => <span
                                            key={item.id}>{`${item.name}${arr.length - 1 === i ? " " : ", "} `}</span>
                                    )
                                }
                                </TabPane>
                        )
                    }
                </Tabs>
            </Modal>

        </>
    );
};

export default ModalDetailPacket;