import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {SearchTable} from "../../util/SearchTable";
import {getHistoryCredit} from "../../appRedux/actions/Balance";
import {Table, Tag} from "antd";
import IntlMessages from "../../util/IntlMessages";

const TableTabCredit = ({stateTab}) => {

    const dispatch = useDispatch()
    const {authUser} = useSelector(state => state.auth)
    const {historyCredit} = useSelector(({historyBalance}) => historyBalance)
    const {manageAccountForFilter} = useSelector(({customer}) => customer)
    const {pageSize} = useSelector(({settings}) => settings);
    const [pagination, setPagination] = useState({page: 1, page_size: pageSize})
    const {getColumnSearchProps} = SearchTable()

    useEffect(() => {
        if (stateTab.key === 'tab3'){
            dispatch(getHistoryCredit(pagination)).then()
        }
    }, [stateTab, pagination])

    const column = [
        {
            title: <><IntlMessages id="balance.balanceApp.date"/></>,
            dataIndex: 'date',
            key: 'date',
            render: text => (
                <span>
                    {new Date(+text).toLocaleString().replace(',', "")}
                </span>
            )
        },
        {
            title: <><IntlMessages id="balance.balanceApp.account"/></>,
            dataIndex: '',
            key: 'manage_account',
            render: (value, record) => record.manage_account.email,
            filterSearch: true,
            filters: manageAccountForFilter.filter(item => item.is_staff && authUser.id !== item.id),
            onFilter: (value, record) => record.manage_account.email.indexOf(value) === 0,
            hidden: true,
        },
        {
            title: <><IntlMessages id="balance.balanceApp.action"/></>,
            dataIndex: 'action',
            key: 'action',
            render: text => (
                <>
                    {
                        text ?
                            <Tag color={'green'}>
                                {<IntlMessages id="balance.balanceApp.coming"/>}
                            </Tag>
                            :
                            <Tag color={'red'}>
                                {<IntlMessages id="balance.balanceApp.spending"/>}
                            </Tag>
                    }
                </>
            )
        },
        {
            title: <><IntlMessages id="balance.balanceApp.amount"/></>,
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: <><IntlMessages id="balance.balanceApp.balance"/></>,
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: <><IntlMessages id="balance.balanceApp.description"/></>,
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps('description'),
        }
    ]

    const onChange = ({current, pageSize}, filters) => {
        const pureFilters = Object.fromEntries(Object.entries((
            {
                search: filters.description,
                manage_account__email__in: filters.manage_account
            }
        ))
            .filter(([_, v]) => v != null));
        setPagination(Object.assign({page: current, page_size: pageSize}, pureFilters))
    }

    return (
        <Table
            rowKey="id"
            dataSource={historyCredit.results}
            className="gx-table-responsive"
            columns={authUser.is_superuser ? column : column.filter(item => item.hidden !== true)}
            size={'small'}
            onChange={onChange}
            pagination={{
                showSizeChanger: true,
                defaultPageSize: pagination.page_size,
                total: historyCredit.count
            }}
        />
    );
};

export default TableTabCredit;