import React from 'react';
import {Button, Card, Form, Input} from "antd";
import IntlMessages from "../../util/IntlMessages";

const FormChangePassword = () => {

    const [form] = Form.useForm()

    const onFinish = values => {
        form.resetFields()
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    return (
        <Card className="gx-card" title={<IntlMessages id="account.formChangePassword.formChangePassword"/>}>
            <Form
                {...formItemLayout}
                form={form}
                name="resetPassword"
                onFinish={onFinish}
            >
                <Form.Item
                    name={"current_password"}
                    label={<IntlMessages id="account.formChangePassword.currentPassword"/>}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your current password!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label={<IntlMessages id="account.formChangePassword.password"/>}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label={<IntlMessages id="account.formChangePassword.confirmPassword"/>}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    labelAlign={"left"}
                >
                    <Button type="primary" htmlType="submit">
                        {<IntlMessages id="account.formChangePassword.сhangePassword"/>}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default FormChangePassword;