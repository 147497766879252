import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {Form, InputNumber, Modal} from "antd";
import {repaymentMoney} from "../../appRedux/actions/Balance";
import IntlMessages from "../../util/IntlMessages";

const ModalRepayment = ({amount}) => {

    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = values => {
        dispatch(repaymentMoney(values))
        form.resetFields()
        setVisible(false)
    }

    const chowModal = () => {
        form.setFieldsValue({
            amount: Math.abs(amount)
        })
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    return (
        <>
            {
                amount < 0 &&
                <span
                    className="gx-link"
                    style={{color: '#038fde'}}
                    onClick={() => chowModal()}
                >
                    {<IntlMessages id="account.formPersonalData.repaymentCredit"/>}
                </span>
            }
            <Modal
                title={<IntlMessages id="account.formPersonalData.repaymentCredit"/>}
                open={visible}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    name="repayment"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="amount"
                        label={<IntlMessages id="account.formPersonalData.repaymentAmount"/>}
                    >
                        <InputNumber style={{marginRight: 0, width: '100%'}}
                                     min={0}
                                     step="0.01"
                                     stringMode
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ModalRepayment;