import * as React from 'react';
import {useState} from "react";
import {Form, Input, Modal, Tooltip} from "antd";
import {useDispatch} from "react-redux";

import {editGroup} from "../../appRedux/actions/PacketGroup";
import IntlMessages from "../../util/IntlMessages";

export const ModalEditGroup = ({group, name}) => {

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false)

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const onFinish = values => {
        values.id = group
        dispatch(editGroup(values));
        form.resetFields()
    };

    const chowModal = () => {
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
        form.resetFields()
    }

    return (
        <>
            <Tooltip
                title={<IntlMessages id="packet.editGroupsApp.editGroup"/>}>
                <i
                    className="icon icon-edit"
                    onClick={chowModal}
                />
            </Tooltip>
            <Modal
                title={<><IntlMessages id="packet.editGroupsApp.editGroup"/>{` -- ${name}`}</>}
                open={visible}
                okText={<IntlMessages id="packet.allApp.change"/>}
                onOk={form.submit}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{remember: true}}
                    name="basic"
                    onFinish={onFinish}
                    className="gx-signin-form gx-form-row0"
                >
                    <Form.Item
                        label='Name group'
                        initialValue={`${name}`}
                        name="name"
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
};

export default ModalEditGroup
