import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Space, Table, Tooltip} from "antd";
import {arrayMoveImmutable} from "array-move";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {CheckCircleTwoTone, ExclamationCircleOutlined, MenuOutlined} from "@ant-design/icons";

import {
    delCountry,
    dropAndDragCountry,
    editCountry,
    removeSortedCountry,
    saveSortedCountry
} from "../../appRedux/actions/PacketCountry";
import ModalEditCountry from "./ModalEditCountry";
import IntlMessages from "../../util/IntlMessages";
import {useIntl} from "react-intl";

const {Column} = Table;
const {confirm} = Modal;

const DragHandle = sortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const ListCountry = () => {
    const {authUser} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const intl = useIntl();
    const {countries, sortedCountry} = useSelector(state => state.packet);

    const [statusSave, setStatusSave] = useState(true);
    const [arr, setArr] = useState([]);

    const showConfirmActive = () => {
        confirm({
            title: intl.formatMessage({id: "packet.allApp.questionClearSorting"}),
            icon: <ExclamationCircleOutlined/>,
            content: intl.formatMessage({id: "packet.allApp.contentClearSorting"}),
            onOk() {
                dispatch(removeSortedCountry())
            },
            onCancel() {
            },
        });
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(countries), oldIndex, newIndex).filter(el => !!el);
            const sortedArr = newData.map((number, index) => {
                const container = {};

                container.id = number.id
                container.account = authUser.id;
                container.country = number.country;
                container.sorted_index = index;

                return container;
            })
            setArr(sortedArr)
            dispatch(dropAndDragCountry(newData));
            if (statusSave === true) {
                setStatusSave(!statusSave)
            }
        }
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = countries.findIndex(x => x.sorted_index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <>
            <Table
                rowKey="sorted_index"
                className="gx-table-responsive"
                dataSource={countries.filter((item => authUser.is_superuser ? item : item.active === true))}
                pagination={false}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                size="small"
                rowClassName={record => !record.active && "disabled-row"}
            >
                <Column title="Sort"
                        dataIndex="sort"
                        align={'center'}
                        key="sort"
                        width={30}
                        render={() => <DragHandle/>}
                />
                {
                    authUser.is_superuser &&
                    <Column title=""
                            dataIndex="active"
                            align='center'
                            key="active"
                            width={20}
                            render={value => (
                                <>
                                    {
                                        value &&
                                        <CheckCircleTwoTone twoToneColor="#52c41a"/>
                                    }
                                </>
                            )}
                    />
                }
                <Column title={<IntlMessages id="packet.listCountryApp.nameCountry"/>} dataIndex="name" key="name"/>
                <Column title={<IntlMessages id="packet.listCountryApp.codeCountry"/>} dataIndex="code" key="code"/>
                {
                    authUser.is_superuser &&
                    <Column title={<IntlMessages id="packet.allApp.action"/>}
                            width={100}
                            dataIndex=""
                            align='center'
                            key="x"
                            render={(text, record) => (
                                <Space size="small">
                                    <Tooltip title={<IntlMessages id="packet.listCountryApp.statusCountry"/>}>
                                        <i
                                            className="icon icon-check"
                                            onClick={() => dispatch(editCountry({
                                                active: !record.active,
                                                id: record.country
                                            }))}
                                        />
                                    </Tooltip>
                                    <ModalEditCountry country={text.country} name={text.name} code={text.code}/>
                                    <Tooltip title={<IntlMessages id="packet.listCountryApp.deleteCountry"/>}>
                                        <i
                                            className="icon icon-trash"
                                            onClick={() => dispatch(delCountry(record.country))}
                                        />
                                    </Tooltip>
                                </Space>
                            )}
                    />
                }
            </Table>
            <Button disabled={statusSave}
                    style={{marginTop: 20}}
                    onClick={() => {
                        setStatusSave(!statusSave)
                        dispatch(saveSortedCountry(arr))
                    }}>
                Save
            </Button>
            <Button disabled={!sortedCountry}
                    style={{marginTop: 20}}
                    onClick={() => {
                        showConfirmActive()
                    }}>
                <IntlMessages id="packet.allApp.clearSorting"/>
            </Button>
        </>
    )
};

export default ListCountry;
