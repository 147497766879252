import {refreshToken} from "./Auth";
import {fetchError, fetchStart, fetchSuccess, showMessage} from "./Common";
import {httpClient} from "../../util/Api";
import {
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_ALL,
    LOAD_CUSTOMERS_ADMIN_MANAGER,
    LOAD_CUSTOMERS_ADMIN_MANAGER_ALL,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_ADMIN_MANAGER,
    LOAD_MANAGE_ACCOUNTS,
    LOAD_MANAGE_ACCOUNT_FOR_FILTER,
} from "../../constants/ActionTypes";
import {internalServerError} from "../../util/config";
import {removeAllFalseValues} from "../../util/removeAllFalseValues";

export const getCustomers = (values) => async dispatch => {
    const str = new URLSearchParams(values).toString();
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`customers/?${str}`)
        dispatch({
            type: LOAD_CUSTOMERS_ALL,
            payload: data
        })
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getCustomers, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const getCustomersAdminManager = (values) => async dispatch => {
    const str = new URLSearchParams(values).toString();
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`customers/admin-manager?${str}`)
        dispatch({
            type: LOAD_CUSTOMERS_ADMIN_MANAGER_ALL,
            payload: data
        })
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getCustomersAdminManager, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const addCustomer = (values) => async dispatch => {
    const {data, pagination, fun} = values
    dispatch(fetchStart());
    try {
        await httpClient.post(`customers/create/`, data)
        fun(pagination)
        dispatch(showMessage('Пользователь успешно добавлен'))
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(refreshToken(addCustomer, values))
        } else if (error.response.status === 400) {
            dispatch(fetchError('Аккаунт c таким именем уже существует'))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }

}

export const loadCustomerAccountOrAdminManager = (data, tab) => dispatch => {
    dispatch({
        type: tab === 'Tab1' ? LOAD_CUSTOMERS : LOAD_CUSTOMERS_ADMIN_MANAGER,
        payload: data
    })
}

export const editCustomer = (values) => async dispatch => {
    const {value, tab} = values
    dispatch(fetchStart());
    try {
        const response = await httpClient.put(`customers/${value.id}/`, value)
        dispatch(loadCustomerAccountOrAdminManager(response.data, tab))
        dispatch(showMessage('Пользователь обновлен'))
        return response
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(editCustomer, values)
            : fetchError(internalServerError)
        dispatch(action)
        return error
    }
}

export const delCustomer = (values) => async dispatch => {
    const {id, tab} = values
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.delete(`customers/${id}/`)
        dispatch({
            type: tab === 'Tab1' ? DELETE_CUSTOMER: DELETE_CUSTOMER_ADMIN_MANAGER,
            payload: data
        })
        dispatch(showMessage('Пользователь удален'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(delCustomer, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const groupDelCustomer = (values, setSelectedRowKeys, fun, paginationIndex) => async dispatch => {
    dispatch(fetchStart());
    const body = JSON.stringify({
        "payload": values
    })
    try {
        await httpClient.delete(`customers/group/`, {data: body})
        dispatch(fun(paginationIndex))
        setSelectedRowKeys([])
        dispatch(showMessage('Выбранные пользователи удаленны'))
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(groupDelCustomer, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const getManageAccount = (values) => async dispatch => {
    const str = new URLSearchParams(removeAllFalseValues(values)).toString();
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`user/manage_account?${str}`)
        dispatch({
            type: LOAD_MANAGE_ACCOUNTS,
            payload: data
        })
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getManageAccount, values)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const getManageAccountForFilter = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const {data} = await httpClient.get(`user/manage_account_filter/`)
        dispatch({
            type: LOAD_MANAGE_ACCOUNT_FOR_FILTER,
            payload: data
        })
        dispatch(fetchSuccess())
    } catch (error) {
        const status = error.response.status
        const action = status === 401
            ? refreshToken(getManageAccountForFilter)
            : fetchError(internalServerError)
        dispatch(action)
    }
}

export const buyPacketForCustomer = (values) => async dispatch => {
    const {customer, data, tab} = values
    dispatch(fetchStart());
    try {
        const res = await httpClient.post(`/customers/buy_packet?customer=${encodeURIComponent(customer.join(','))}`, data)
        dispatch(loadCustomerAccountOrAdminManager(res.data, tab))
        dispatch(showMessage('Пакет куплен'))
    } catch (e) {
        if (e.response.status === 401) {
            dispatch(refreshToken(buyPacketForCustomer, values))
        } else if (e.response.status === 402) {
            dispatch(fetchError(e.response.data.error))
        } else if (e.response.status === 409) {
            dispatch(fetchError(e.response.data.error))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}

export const buyPacketForCustomer365 = (values) => async dispatch => {
    const {customer, data, tab} = values
    dispatch(fetchStart());
    try {
        const res = await httpClient.post(`/customers/buy_packet_365?customer=${encodeURIComponent(customer.join(','))}`, data)
        // dispatch(loadCustomerAccountOrAdminManager(res.data, tab))
        dispatch(showMessage('Пакет куплен'))
    } catch (e) {
        if (e.response.status === 401) {
            dispatch(refreshToken(buyPacketForCustomer, values))
        } else if (e.response.status === 402) {
            dispatch(fetchError(e.response.data.error))
        } else if (e.response.status === 409) {
            dispatch(fetchError(e.response.data.error))
        } else {
            dispatch(fetchError(internalServerError))
        }
    }
}
